'use strict'

import slick from 'slick-carousel';

jQuery(function () {
    $('.items-slick-holder').slick({
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 2,
        arrows: false,
        autoplay: false,
        autoplaySpeed: 3000,
        responsive: [
            {
              breakpoint: 1500,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
              }
            },
            {
              breakpoint: 993,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              }
            },
            {
              breakpoint: 769,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
          ]
    });

    $('.items-slick-holder').on('setPosition', function (event, slick) {
        slick.$slides.css('height', slick.$slideTrack.height() + 'px');
    });

    //Slick Nav
    $('.slick-nav.home-items').on('click', function (e) {
        e.preventDefault();
        if ($(this).hasClass('prev')) {
            $('.items-slick-holder').slick('slickPrev');
        }
        if ($(this).hasClass('next')) {
            $('.items-slick-holder').slick('slickNext');
        }

    })
})